import PortfolioGrid from "components/PortfolioGrid";
import SEO from "components/SEO";
import { Link } from "gatsby";
import Layout from "layout";
import React from "react";
import {
  BasePageContext,
  TemplateHeadProps,
  TemplatePageProps,
} from "utils/types";

const htHomeImg = require("images/hillary-taylor-home.jpg").default;


export default (props: TemplatePageProps<BasePageContext>) => {
  const { appData } = props.pageContext;
  const blogPosts = appData.blogPosts.slice().sort((prev, value) => { 
    return Date.parse(value.created) - Date.parse(prev.created)
  });

  return (
    <Layout bodyClass="blog" appData={appData}>
      <div className="container--medium blog-container">
        {blogPosts.map((post, index) => {
          if (index === 0) {
            return (
              <div key={index} className="blog-main">
                <div className="blog-heading spaced-25">
                  <Link to={`/blog/${post.slug}`}>
                    <h1
                      className="with-divider"
                      dangerouslySetInnerHTML={{ __html: post.title }}
                    ></h1>
                  </Link>
                </div>
                <Link to={`/blog/${post.slug}`}>
                  <img src={post.headImage} className="featured-image" />
                </Link>
                <Link to={`/blog/${post.slug}`} className="read-more">
                  Read Full Blog Post
                </Link>
              </div>
            );
          } else {
            return (
              <div className="container--medium blog-grid" key={index}>
                <div className="blog-layout blog-card">
                  <Link to={`/blog/${post.slug}`}>
                    <img src={post.headImage} />
                    <div className="blog-heading">
                      <h4 dangerouslySetInnerHTML={{ __html: post.title }}></h4>
                    </div>
                  </Link>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: post.body.slice(0, 100) + "...",
                    }}
                  />
                  <Link to={`/blog/${post.slug}`} className="read-more">
                    Read Blog
                  </Link>
                </div>
              </div>
            );
          }
        })}
      </div>
    </Layout>
  );
};

export const Head = (props: TemplateHeadProps<BasePageContext>) => {
  const { blogPosts } = props.pageContext.appData;

  return (
    <SEO
      title="Blog"
      ogMeta={{
        image: blogPosts[0]?.headImage ?? htHomeImg,
      }}
    ></SEO>
  );
};
