import React, { FC, PropsWithChildren } from "react";

const defaultImage = require("../images/hillary-taylor-about.jpg").default;

export type MetaTagName = "description" | "keywords" | "author";
export type OgProperties = "title" | "type" | "image" | "description";

export interface SEOProps {
  title: string;
  meta?: Partial<Record<MetaTagName, string>>;
  // See: https://ahrefs.com/blog/open-graph-meta-tags/
  ogMeta?: Partial<Record<OgProperties, string>>;
}

const SEO: FC<PropsWithChildren<SEOProps>> = (props) => {
  const { children, title } = props;

  const meta: SEOProps["meta"] = {
    description: "Interior design by Hillary Taylor",
    ...props.meta,
  };

  const ogMeta: SEOProps["ogMeta"] = {
    image: defaultImage,
    title: `${title} |  Hillary Taylor Interiors`,
    ...props.ogMeta,
  };

  return (
    <>
      <title>{`${title} | Hillary Taylor Interiors`}</title>
      <link
        rel="icon"
        type="image/x-icon"
        href={require("../images/favicon.ico").default}
      />

      {Object.entries(meta).map(([name, content], i) => (
        <meta key={i} name={name} content={content} />
      ))}

      {Object.entries(ogMeta).map(([property, content], i) => (
        <meta key={i} property={`og:${property}`} content={content} />
      ))}

      {children}
    </>
  );
};

export default SEO;
